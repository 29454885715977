import config from "../config/index";

const handleResponse = async (response) => {
  const string = await response.text();
  let json = {};
  if (typeof string === 'string' && string.trim() !== '') {
    try {
      json = JSON.parse(string);
    } catch(err) {
      console.log('Invalid error received from server: ', err);
    }
  }

  if (response.status === 200) {
    // success
    // console.log('success: ', json);
    return json;
  } else {
    // error
    console.log('error: ', json);
    throw errorHandler(json);
  }
}

const errorHandler = (err) => {
  let message = 'There was a problem with your request.';

  if (typeof err === 'string') {
    message = err;
  } else if (err.errors && err.errors.message) {
    message = err.errors.message;
  } else if (typeof message !== 'string') {
    message = JSON.stringify(message);
  }

  return message;
}

const getAvailability = async ({ zip, r }) => {
  const url = `${config.apiUrl}/public/availabillity?zip=${zip}&r=${r}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return await handleResponse(response);
}

const getStoreDetails = async (id) => {
  const url = `${config.apiUrl}/public/store-details?id=${id}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return await handleResponse(response);
}

const getSearch = async (id) => {
  const url = `${config.apiUrl}/public/search?id=${id}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return await handleResponse(response);
}

const checkPhone = async (phone) => {
  const url = `${config.apiUrl}/public/check-phone`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      phone
    })
  });
  return await handleResponse(response);
}

const createPaymentIntent = async () => {
  const url = `${config.apiUrl}/public/create-payment-intent`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return await handleResponse(response);
}

const registerPhone = async (phone, stripeId) => {
  const url = `${config.apiUrl}/public/register-phone`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      phone,
      stripeId
    })
  });
  return await handleResponse(response);
}

const subscribeForNotifications = async (data) => {
  const url = `${config.apiUrl}/public/notifications-subscribe`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data
    })
  });
  return await handleResponse(response);
}

const unsubscribeForNotifications = async (uuid, id) => {
  const url = `${config.apiUrl}/public/notifications-unsubscribe`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      uuid: uuid,
      id: id
    })
  });
  return await handleResponse(response);
}

const openedLink = async (uuid) => {
  const url = `${config.apiUrl}/public/opened-link`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      uuid
    })
  });
  return await handleResponse(response);
}

const getStats = async () => {
  const url = `${config.apiUrl}/public/stats`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return await handleResponse(response);
}

const getAnnouncement = async () => {
  const url = `${config.apiUrl}/public/announcement`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return await handleResponse(response);
}

export default {
  getAvailability,
  getStoreDetails,
  getSearch,
  checkPhone,
  createPaymentIntent,
  registerPhone,
  subscribeForNotifications,
  unsubscribeForNotifications,
  openedLink,
  getStats,
  getAnnouncement
};
