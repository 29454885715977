import React, { useEffect, useState } from "react";
import Typist from 'react-typist';
import { Animated } from 'react-animated-css';

const STRIPE_PRICE_ID_FREE = 'price_1HFTILBeHyFamaEwr6BpOKBI';
const STRIPE_PRICE_ID_PRO_MONTH = 'price_1I6JP4BeHyFamaEwZLxSjBaC';
const STRIPE_PRICE_ID_PRO_YEAR = 'price_1HFTIMBeHyFamaEwHxnwD4ys';

const Contact = () => {
  const openEmail = () => {
    window.open('mailto:info@covidvaxmap.com', '_blank');
  }

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">Contact Us</h2>
          <p className="mt-3 text-xl text-gray-500 sm:mt-4">Please send all questions, comments, and concerns to <a onClick={openEmail} href="#" className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">info@covidvaxmap.com</a></p>
        </div>
      </div>
    </>
  );
}


export default Contact;