import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import PubSub from "pubsub-js";

import api from "../apis/public";

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#000',
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      },
      ':focus': {
        outline: 'none',
        boxShadow: '0 0 0 3px rgba(164, 202, 254, 0.45)',
        borderColor: '#a4cafe'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  }
};

const CheckoutForm = ({ callback, email, phone }) => {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    //createPaymentIntent();
  }, [])

  // Handle real-time validation errors from the card Element.
  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    PubSub.publish('loading', true);
    let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

    try {
      let data = await api.createPaymentIntent();

      setClientSecret(data.clientSecret);

      let body = {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {}
        }
      };

      if (email) {
        body.receipt_email = email;
        body.payment_method.billing_details.email = email;
      }

      if (phone) {
        body.payment_method.billing_details.phone = phone;
      }

      const payload = await stripe.confirmCardPayment(data.clientSecret, body);

      if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        setProcessing(false);
      } else {
        setError(null);
        setProcessing(false);
        setSucceeded(true);

        callback(payload.paymentIntent);
      }
    } catch(err) {
      setError(err);
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
    } finally {
      PubSub.publish('loading', false);
      clearTimeout(timeout);
    }
  };

  const createPaymentIntent = async () => {
    PubSub.publish('loading', true);
    let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

    try {
      let data = await api.createPaymentIntent();

      setClientSecret(data.clientSecret);
    } catch(err) {
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
    } finally {
      PubSub.publish('loading', false);
      clearTimeout(timeout);
    }
  }

  return (
    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
      <div>
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
          <svg className="h-6 w-6 text-red-600" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z" fill="currentColor"/>
          </svg>
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
            Purchase Text Message Alerts
          </h3>
          <p className="text-md text-gray-600 mt-2 mb-6">The entered phone number has not been registered for paid text message alerts. Due to increasing costs of keeping this site running, we are asking users to pay a <strong>one-time</strong> fee of $1.00 to access <strong>unlimited</strong> text message alerts. Thanks!</p>
          <div className="mt-4">
            <fieldset>
              <legend className="block text-sm font-medium leading-5 text-gray-700 text-left">Card Details</legend>
              <div className="mt-3 bg-white rounded-md shadow-sm">
                <div>
                  <input aria-label="Card number" className="form-input relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-md sm:leading-5" placeholder="Name on card (ex. John Doe)" />
                </div>
                <div className="-mt-px flex">
                  <CardElement
                    id="card-element"
                    options={CARD_ELEMENT_OPTIONS}
                    onChange={handleChange}
                    className="form-input relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
            </fieldset>
            <div className="mt-3 card-errors block text-sm leading-5 text-red-600" role="alert">{error}</div>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
          <button onClick={e => handleSubmit(e)} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5">
            Pay $1
          </button>
        </span>
        <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
          <button onClick={e => callback()} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
            Cancel
          </button>
        </span>
      </div>
    </div>
  );
}

// Setup Stripe.js and the Elements provider
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CardForm = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm {...props} />
    </Elements>
  );
}

export default CardForm;