import React, { useEffect, useState } from "react";
import Typist from 'react-typist';
import { Animated } from 'react-animated-css';
import PubSub from "pubsub-js";
import moment from "moment";
import GoogleMapReact, { fitBounds } from 'google-map-react';
import animateScrollTo from 'animated-scroll-to';
import zipcodes from 'zipcodes';
import InputMask from 'react-input-mask';
import queryString from "query-string";
import { Map, Marker, Popup, LngLatBounds } from "maplibre-gl";

import api from "../apis/public";
import PaymentForm from "../components/PaymentForm";

const GOOGLE_MAPS_KEY1 = 'AIzaSyBMRxqUHsLjEiKHbRjBdruk4YIDElgo-OU';
const GOOGLE_MAPS_KEY2 = 'AIzaSyDeUw0HSTOMZ8-ONgwk9yjxqhHsdWk6-dw';
const GOOGLE_MAPS_KEY = null;
const AnyReactComponent = ({ text }) => <div>{text}</div>;
const validRs = [1, 5, 10, 25, 50];
const promoCard = false;
const donationAsk = false;
const logos = ['pharmacaintegrative', 'albertsons', 'safeway', 'walgreens', 'walmart', 'samsclub', 'kroger', 'kingsoopers', 'cvs', 'riteaid', 'vons', 'sav-on', 'ralphs', 'oscodrug', 'publixsupermarkets', 'dillons', 'randalls', 'h-e-b', 'citymarket', 'meijer', 'winn-dixie', 'hy-vee', 'costcowholesalecorporation'];

const MapSection = () => {
  const [ zip, setZip ] = useState('');
  const [ r, setR ] = useState(5);
  const [ zoom, setZoom ] = useState(5);
  const [ center, setCenter ] = useState({
    lat: 39.5,
    lng: -97
  });
  const [ availability, setAvailability ] = useState([]);
  const [ markers, setMarkers ] = useState([]);
  const [ visibleCards, setVisibleCards ] = useState([]);
  const [ selectedStore, setSelectedStore ] = useState(null);
  const [ zipError, setZipError ] = useState(null);
  const [ phoneError, setPhoneError ] = useState(null);
  const [ emailError, setEmailError ] = useState(null);
  const [ showNotificationsBanner, setShowNotificationsBanner ] = useState(false);
  const [ showNotificationsModal, setShowNotificationsModal ] = useState(false);
  const [ notificationsInfo, setNotificationsInfo ] = useState({
    phone: '',
    email: ''
  });
  const [ validNotificationsInfo, setValidNotificationsInfo ] = useState(false);
  const [ mapGl, setMapGl ] = useState(null);

  useEffect(() => {
    // load map
    let newMapGl;
    if (!GOOGLE_MAPS_KEY) {
      newMapGl = loadMap();
    }

    let zipParam = parseInt(queryString.parse(window.location.search).zip);
    setZip(zipParam || '');
    let rParam = parseInt(queryString.parse(window.location.search).r);
    setR(rParam || 5);
    let qParam = parseInt(queryString.parse(window.location.search).q);

    if (qParam) {
      getSearch(qParam, newMapGl);
    } else if (zipParam && rParam) {
      if (validRs.includes(rParam)) {
        PubSub.publish('loading', true);
        setTimeout(() => {
          validate(zipParam, rParam, newMapGl);
        }, 1500);
      } else {
        PubSub.publish('alert', { type: 'danger', title: 'Error', body: 'Invalid distance' });
      }
    } else if (navigator.geolocation) {
      getZipFromGeolocation(newMapGl);
    } else {
      console.log('Geolocation disabled');
    }

    // check if opened link
    if (queryString.parse(window.location.search).id) {
      openedLink();
    }
  }, []);

  const getSearch = async (qParam, newMapGl) => {
    try {
      PubSub.publish('loading', true);

      let search = await api.getSearch(qParam);
  
      let zipParam = parseInt(search.zip);
      setZip(zipParam || '');
      let rParam = parseInt(search.r);
      setR(rParam || 5);
  
      if (validRs.includes(rParam)) {
        validate(zipParam, rParam, newMapGl);
      } else {
        PubSub.publish('alert', { type: 'danger', title: 'Error', body: 'Invalid distance' });
        PubSub.publish('loading', false);
      }
    } catch(err) {
      console.log(err);
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
      PubSub.publish('loading', false);
    }
  }

  const openedLink = async () => {
    await api.openedLink(queryString.parse(window.location.search).id);
  }

  const getZipFromGeolocation = async (newMapGl) => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      let result = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_KEY1}`);

      if (result.status === 200) {
        result = await result.json();

        let zipCode = result.results[0].address_components.find(c => c.types[0] === 'postal_code');
        if (!zip && zipCode) {
          setZip(parseInt(zipCode.long_name));
          setCenter({ lat, lng });
          setZoom(13);

          if (!GOOGLE_MAPS_KEY && newMapGl) {
            newMapGl.setCenter({ lat, lng });
            newMapGl.zoomTo(12);
          }
        }
      }
    });
  }

  const getAvailability = async ({ zip, r, newMapGl }) => {
    animateScrollTo(document.getElementById('map').offsetTop);
    setShowNotificationsBanner(false);

    PubSub.publish('loading', true);
    let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

    try {
      let availability = await api.getAvailability({ zip, r });

      setAvailability(availability);

      let markers = JSON.parse(JSON.stringify(availability));
      markers = markers.reverse();
      setMarkers(markers);

      // pop map
      if (!GOOGLE_MAPS_KEY) {
        populateMap(markers, newMapGl);
      }

      if (availability && availability.length > 0) {
        autofitBounds(availability, newMapGl);
        animateScrollTo([0, null], {
          elementToScroll: document.getElementById('card-row')
        });
        // check if vaccines are available
        if (availability.find(a => a.appointmentsAvailable)) {
          //PubSub.publish('alert', { type: 'success', title: 'Hooray!', body: 'Vaccines found!' });
          PubSub.publish('modal', (
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                    Success!
                  </h3>
                  <div className="mt-2">
                    <p className="text-md text-gray-600">
                      We found availability in your area!
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button onClick={e => PubSub.publish('modal', null)} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
                  Ok
                </button>
              </div>
            </div>
          ));
        } else {
          setShowNotificationsBanner(true);
          //PubSub.publish('alert', { type: 'danger', title: 'Sorry...', body: 'No vaccines found' });
          PubSub.publish('modal', (
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                    Sorry...
                  </h3>
                  <div className="mt-2">
                    <p className="text-md text-gray-600">
                      We were unable to find vaccine availability. Please adjust your distance or zip code and try again.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button onClick={e => { PubSub.publish('modal', null); setShowNotificationsModal(true); }} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
                  Get Notified
                </button>
                <button onClick={e => PubSub.publish('modal', null)} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">
                  Ok
                </button>
              </div>
            </div>
          ));
        }
      } else {
        //PubSub.publish('alert', { type: 'danger', title: 'Error', body: 'No stores found' });
        PubSub.publish('modal', (
          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            <div>
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                  Oops!
                </h3>
                <div className="mt-2">
                  <p className="text-md text-gray-600">
                    No vaccine providers were found. Please adjust your distance or zip code and try again.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <button onClick={e => PubSub.publish('modal', null)} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
                Ok
              </button>
            </div>
          </div>
        ));
      }
    } catch(err) {
      console.log(err);
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
    } finally {
      PubSub.publish('loading', false);
      clearTimeout(timeout);
    }
  }

  const loadMap = () => {
    let newMapGl = new Map({
      container: "mapGl",
      style: "https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json",
      //bounds: mapBounds,
      // fitBoundsOptions: {
      //   padding: 10,
      // },
      center: center,
      zoom: zoom
    });

    setMapGl(newMapGl);

    return newMapGl;
  }

  const populateMap = (stores, newMapGl) => {
    let map = mapGl || newMapGl;

    if (map) {
      stores.forEach((store, index) => {
        let marker = new Marker({
          offset: [0, -50/2],
          color: selectedStore && selectedStore.id === store.id ? '#5145cd' : store.appointmentsAvailable == true ? '#1dbf4d' : store.inStock ? '#f26b6b' : '#949494'
        }).setLngLat([ store.location.lng, store.location.lat ]).addTo(map);
  
        marker.getElement().addEventListener('click', (e) => {
          storeClicked(e, store, index);
          map.flyTo({ center: [ store.location.lng, store.location.lat ] });
        });
      });
    }
  }

  const zipUpdating = (event) => {
    event.persist();
    let zip = event.target.value;
    let key = event.key;

    if (key === 'Enter') {
      event.target.blur();
      validate();
    } else {
      setZip(zip);
    }
  }

  const validate = (zipParam, rParam, newMapGl) => {
    PubSub.publish('loading', false);
    let zipCode = zipParam || zip;
    let radius = rParam || r;

    if (zipCode && zipcodes.lookup(zipCode)) {
      setZipError(null);
      getAvailability({ zip: zipCode, r: radius, newMapGl });
    } else {
      setZipError('Must enter a valid zip code.');
    }
  }

  const getLogo = (store) => {
    try {
      let brand = store.brand.replace(/ +/g, "");
      if (logos.includes(brand.toLowerCase())) {
        return `stores/${brand.toLowerCase()}-logo.png`;
      } else {
        throw new Error('Image not found.');
      }
    } catch (err) {
      let parent = store.parent.replace(/ +/g, "");
      parent = parent.toLowerCase();
      if (parent && parent === 'kroger') {
        return `stores/kroger-logo.png`;
      } else {
        return `brand/logo-horizontal-on-white.png`;
      }
    }
  }

  const storeClicked = async (e, store, index) => {
    e.preventDefault();
    setCenter({
      lat: parseFloat(store.location.lat),
      lng: parseFloat(store.location.lng)
    });
    setSelectedStore(store);

    if (store.guid) {
      PubSub.publish('loading', true);
      let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);
  
      try {
        let details = await api.getStoreDetails(store.id);
        if (details) {
          let currentStore = JSON.parse(JSON.stringify(store));
          currentStore.inventory = details.inventory || currentStore.inventory;
          currentStore.signupUrl = details.signupUrl || currentStore.signupUrl;
          currentStore.url = details.url || currentStore.url;
          setSelectedStore(currentStore);
        }
      } catch(err) {
        console.log(err);
        PubSub.publish('alert', { type: 'danger', title: 'Error', body: 'Store details unavailable' });
      } finally {
        PubSub.publish('loading', false);
        clearTimeout(timeout);
      }
    }
  }

  const goToStoreSite = (url) => {
    window.open(url, '_blank');
  }

  const callStore = (phone) => {
    window.location.href = `tel:${phone}`;
  }

  const promoClicked = (url) => {
    window.open(url, '_blank');
  }

  const autofitBounds = (availability, newMapGl) => {
    if (GOOGLE_MAPS_KEY) {
      const bounds = new window.google.maps.LatLngBounds();
      availability.map(store => {
        if (store && store.location && store.location.lat && store.location.lng) {
          let location = new window.google.maps.LatLng(parseFloat(store.location.lat), parseFloat(store.location.lng));
          bounds.extend(location);
        }
        return store;
      });
  
      const height = document.getElementById('map-container').clientHeight;
      const width = document.getElementById('map-container').clientWidth;
  
      let parsedBounds = {
        ne: {
          lat: bounds.getNorthEast().lat(),
          lng: bounds.getNorthEast().lng()
        },
        sw: {
          lat: bounds.getSouthWest().lat(),
          lng: bounds.getSouthWest().lng()
        }
      };
  
      let { center, zoom } = fitBounds(parsedBounds, { width, height });
      zoom = parseFloat(zoom - 0.5);
  
      let parsedCenter = bounds.getCenter();
  
      parsedCenter = {
        lat: parsedCenter.lat(),
        lng: parsedCenter.lng()
      };
  
      setCenter(parsedCenter);
      setZoom(availability.length === 1 ? 13 : zoom);
    } else {
      let map = mapGl || newMapGl;
      let bounds = new LngLatBounds();

      availability.map(store => {
        if (store && store.location && store.location.lat && store.location.lng) {
          bounds.extend([store.location.lng, store.location.lat]);
        }
        return store;
      });

      map.fitBounds(bounds);
      // update map
      // mapGl.setCenter(parsedCenter);
      // mapGl.zoomTo(availability.length === 1 ? 12 : zoom - 1);
    }
  };

  const notificationsInfoUpdating = (event, field) => {
    event.persist();
    let value = event.target.value;

    let info = JSON.parse(JSON.stringify(notificationsInfo));

    info[field] = value;

    setNotificationsInfo(info);
  }

  const isEnabled = () => {
    let { phone, email } = notificationsInfo;
    let isValid = true;

    phone = phone ? phone.trim() : null;
    email = email ? email.trim() : null;

    if (!phone && !email) {
      isValid = false;
    }

    if (phone && phone.length !== 12) {
      isValid = false;
    }

    return isValid;
  }

  const validateNotificationsInfo = () => {
    let { phone, email } = notificationsInfo;
    let isValid = true;
    setPhoneError(false);

    phone = phone ? phone.trim() : null;
    email = email ? email.trim() : null;

    if (!phone && !email) {
      isValid = false;
    }

    if (phone && phone.length !== 12) {
      setPhoneError('Phone number must be a valid 10-digit US number');
      isValid = false;
    }

    setValidNotificationsInfo(isValid);
    return isValid;
  }

  const openBMC = () => {
    if (document.getElementById('bmc-wbtn')) {
      document.getElementById('bmc-wbtn').click();
    }
  }

  const subscribeForNotifications = async (override) => {
    if (validateNotificationsInfo()) {
      PubSub.publish('loading', true);
      let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

      let phone = notificationsInfo.phone;
      let email = notificationsInfo.email;
  
      try {
        let data = {
          phone,
          email,
          zip,
          r
        };

        // first check if phone is registered
        if (phone && (!override || override === undefined)) {
          let isRegistered = await api.checkPhone(phone);

          if (!isRegistered || (isRegistered && !isRegistered.phone)) {
            setShowNotificationsModal(false);
            showPaymentModal();
            return true;
          }
        }

        await api.subscribeForNotifications(data);
        
        PubSub.publish('modal', (
          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            <div>
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <svg className="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                  Success!
                </h3>
                <div className="mt-2">
                  <p className="text-md text-gray-600">
                    You have successfully signed up for real-time alerts.
                  </p>
                  {email && (
                    <p className="text-md text-gray-600 font-bold">
                      (Please also check your spam folder!)
                    </p>
                  )}
                  {phone && donationAsk && (
                    <div className="mt-4 p-2 rounded-md border-2 border-indigo-600 bg-indigo-100 text-indigo-600">
                      <p className="text-md font-semibold">
                        Help! As this site has grown in popularity, so have the costs to keep it running. Currently, CovidVaxMap is self-funded, with the biggest expense being text message alerts. Please consider a <span onClick={openBMC} class="underline cursor-pointer">small donation</span> to help keep this site free to use for everyone. Thanks!
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <button onClick={e => PubSub.publish('modal', null)} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
                Ok
              </button>
            </div>
          </div>
        ));
        setShowNotificationsModal(false);
        setShowNotificationsBanner(false);
      } catch(err) {
        console.log(err);
        PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
      } finally {
        PubSub.publish('loading', false);
        clearTimeout(timeout);
      }
    }
  }

  const showTextInfoModal = () => {
    PubSub.publish('modal', (
      <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div>
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100">
            <svg className="h-6 w-6 text-indigo-600" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.22766 9C8.77678 7.83481 10.2584 7 12.0001 7C14.2092 7 16.0001 8.34315 16.0001 10C16.0001 11.3994 14.7224 12.5751 12.9943 12.9066C12.4519 13.0106 12.0001 13.4477 12.0001 14M12 17H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
              Why do we charge for text message alerts?
            </h3>
            <div className="mt-2">
              {/* <p className="text-md text-gray-600">
                You have successfully signed up for real-time alerts.
              </p> */}
              <div className="mt-4 p-2 rounded-md border-2 border-indigo-600 bg-indigo-100 text-indigo-600">
                <p className="text-md font-semibold text-left">
                  As this site has grown in popularity, so have the costs to keep it running. Currently, I self-fund CovidVaxMap by myself (and more than happy to do so!), with the biggest expense being text message alerts.
                  <br></br>
                  <br></br>
                  Because of this, I'm asking users to pay a one-time fee of $1 per phone number. Once you register a number, you can subscribe to as many different searches as you'd like and will receive free text message alerts until you unsubscribe.
                  <br></br>
                  <br></br>
                  If you'd like to support CovidVaxMap in other ways, please consider a <span onClick={openBMC} class="underline cursor-pointer">small donation</span> to help keep this site free to use for everyone. Thanks!
                </p>
                <p className="text-right text-md font-semibold mt-4">- John</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
          <button onClick={e => { PubSub.publish('modal', null); setShowNotificationsModal(true); }} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
            Ok
          </button>
        </div>
      </div>
    ));
    setShowNotificationsModal(false);
  }

  const showPaymentModal = () => {
    PubSub.publish('modal', (
      <PaymentForm
        callback={async (payload) => {
          PubSub.publish('modal', null);
          setShowNotificationsModal(true);

          PubSub.publish('loading', true);
          let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

          try {
            // first, register phone
            await api.registerPhone(notificationsInfo.phone, payload.id);

            PubSub.publish('loading', false);
            clearTimeout(timeout);
  
            // then retry subscribe
            subscribeForNotifications(true);
          } catch(err) {
            console.log(err);

            PubSub.publish('loading', false);
            clearTimeout(timeout);
          }
        }}
        email={notificationsInfo.email || ''}
        phone={notificationsInfo.phone || ''}
      />
    ));
  }

  const slotType = (type) => {
    if (type) {
      type = type.trim();
      type = type.replace(/_/g, " ");
      type = type.toUpperCase();
    }

    return type;
  }

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const parseInventoryName = (name) => {
    let words = name.split('_');
    words = words.map(word => capitalizeFirstLetter(word))
    return words.join(' ')
  }

  return (
    <>
      <div className="bg-gray-50 pb-12">
        <div className="max-w-screen-xl mx-auto pt-0 px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
            <div>
              <label htmlFor="zip" className="block text-md leading-5 font-medium text-gray-700 mb-2">
                Zip Code
              </label>
              <div className="mt-2 relative flex rounded-md shadow-sm">
                <InputMask
                  id="zip"
                  name="zip"
                  autoComplete="postal-code"
                  className="form-input flex-1 block w-full rounded-none rounded-l-md transition duration-150 ease-in-out text-lg font-semibold sm:leading-5 p-2"
                  placeholder="Enter Zip Code (ex. 90210)"
                  type="tel"
                  mask="99999"
                  maskChar={null}
                  value={zip}
                  onChange={e =>
                    zipUpdating(e)
                  }
                  onKeyUp={e => zipUpdating(e)}
                />
                <button onClick={e => validate()} className="inline-flex items-center p-2 px-4 rounded-r-md border border-l-0 text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none text-lg font-semibold">
                  Search
                </button>
              </div>
              {zipError && (
                <p className="mt-2 text-md text-red-600 font-medium" id="zip-error">{zipError}</p>
              )}
            </div>
            <div className="sm:text-right">
              <label htmlFor="zip" className="block text-md leading-5 font-medium text-gray-700 mb-2">
                Distance (miles)
              </label>
              <span className="relative z-0 inline-flex shadow-sm rounded-md">
                <button onClick={e => setR(1)} type="button" className={`relative inline-flex items-center p-2 px-4 rounded-l-md border border-gray-300 ${r === 1 ? 'text-white bg-indigo-600' : 'text-gray-700 bg-white'} text-lg font-semibold focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}>
                  1
                </button>
                <button onClick={e => setR(5)} type="button" className={`-ml-px relative inline-flex items-center  p-2 px-4 border border-gray-300 ${r === 5 ? 'text-white bg-indigo-600' : 'text-gray-700 bg-white'} text-lg font-semibold focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}>
                  5
                </button>
                <button onClick={e => setR(10)} type="button" className={`-ml-px relative inline-flex items-center  p-2 px-4 border border-gray-300 ${r === 10 ? 'text-white bg-indigo-600' : 'text-gray-700 bg-white'} text-lg font-semibold focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}>
                  10
                </button>
                <button onClick={e => setR(25)} type="button" className={`-ml-px relative inline-flex items-center  p-2 px-4 border border-gray-300 ${r === 25 ? 'text-white bg-indigo-600' : 'text-gray-700 bg-white'} text-lg font-semibold focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}>
                  25
                </button>
                <button onClick={e => setR(50)} type="button" className={`-ml-px relative inline-flex items-center  p-2 px-4 rounded-r-md border border-gray-300 ${r === 50 ? 'text-white bg-indigo-600' : 'text-gray-700 bg-white'} text-lg font-semibold focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}>
                  50
                </button>
              </span>
            </div>
          </div>
          <div id="map-container" className="relative overflow-hidden shadow-lg rounded-lg" style={{ height: '70vh', width: '100%' }}>
            {GOOGLE_MAPS_KEY ? (
              <GoogleMapReact
                bootstrapURLKeys={{ key: GOOGLE_MAPS_KEY }}
                center={center}
                zoom={zoom}
                options={{ scrollwheel: true }}
              >
                {(markers && markers.length > 0) ? markers.map((store, index) =>
                  <AnyReactComponent
                    key={`div-${index}`}
                    lat={store.location.lat}
                    lng={store.location.lng}
                    text={(
                      <>
                        <svg onClick={e => storeClicked(e, store, index)} className="w-12 h-12" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M5.05025 4.05025C7.78392 1.31658 12.2161 1.31658 14.9497 4.05025C17.6834 6.78392 17.6834 11.2161 14.9497 13.9497L10 18.8995L5.05025 13.9497C2.31658 11.2161 2.31658 6.78392 5.05025 4.05025ZM10 11C11.1046 11 12 10.1046 12 9C12 7.89543 11.1046 7 10 7C8.89543 7 8 7.89543 8 9C8 10.1046 8.89543 11 10 11Z" fill={`${selectedStore && selectedStore.id === store.id ? '#5145cd' : store.appointmentsAvailable == true ? '#1dbf4d' : store.inStock ? '#f26b6b' : '#949494'}`} />
                        </svg>
                      </>
                    )}
                  >
                  </AnyReactComponent>
                ) : null}
                {(visibleCards && visibleCards.length > 0) ? visibleCards.map((store, index) =>
                  <AnyReactComponent
                    key={`card-${index}`}
                    lat={store.location.lat}
                    lng={store.location.lng}
                    text={(
                      <div className="bg-white w-24 h-24">
                        <h3>{index}</h3>
                      </div>
                    )}
                  >
                  </AnyReactComponent>
                ) : null}
              </GoogleMapReact>
            ) : (
              <div id="mapGl" className="w-full h-full"></div>
            )}

            {showNotificationsBanner && (
              <div className="absolute top-0 inset-x-0 pt-2 sm:pt-4">
                <div className="max-w-3xl mx-auto px-2 sm:px-6 lg:px-8">
                  <div className="p-2 rounded-lg bg-indigo-600 shadow-lg sm:p-3">
                    <div className="flex items-center justify-between flex-wrap">
                      <div className="w-0 flex-1 flex items-center">
                        <span className="flex p-2 rounded-lg bg-indigo-800">
                          <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                          </svg>
                        </span>
                        <p className="ml-3 font-medium text-white truncate">
                          <span>
                            Want alerts when vaccines are available?
                          </span>
                        </p>
                      </div>
                      <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                        <button onClick={e => setShowNotificationsModal(true)} type="button" className="flex items-center justify-center w-full px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50">
                          Get Notified
                        </button>
                      </div>
                      <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                        <button onClick={e => setShowNotificationsBanner(false)} type="button" className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white">
                          <span className="sr-only">Dismiss</span>
                          <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(availability && availability.length > 0) ? (
              <div id="card-row" className="overflow-x-scroll flex absolute bottom-0 py-4 sm:py-8 w-full">
                {promoCard && (
                  <div key={`card-holder`} className={`rounded-lg shadow-lg bg-white flex flex-col w-60 sm:w-96 flex-shrink-0 ml-4 sm:ml-6 lg:ml-8 mr-4 sm:mr-6 lg:mr-8 py-2 px-3 sm:p-4`}>
                    <div className="sm:flex">
                      <div className="inline-flex rounded-full overflow-hidden h-12 w-full sm:w-24 sm:h-24">
                        <img className="object-contain w-full h-full" src={'illustration.png'} alt="" />
                      </div>
                      <div className="sm:ml-4 mt-1 flex-1 flex flex-col justify-center items-start">
                        <div className="flex items-center w-full">
                          <h3 className="font-bold text-lg text-gray-900 mb-1 sm:mb-2">Vaccine Passport Protector</h3>
                        </div>
                        <span className={`inline-flex items-center mb-1 sm:mb-2 px-2 py-1 rounded-md text-sm font-medium bg-green-100 text-green-800}`}>
                          In Stock
                        </span>
                        {/* <p className="text-sm sm:text-md w-full">
                          Protect your CDC card
                        </p> */}
                        <p className="mt-1 truncate text-sm font-semibold sm:text-md w-full">
                          Sponsored
                        </p>
                      </div>
                    </div>
                    <button onClick={e => promoClicked('https://amzn.to/3lilDuR')} type="button" className="flex-shrink-0 mt-auto w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      View Details
                    </button>
                  </div>
                )}
                {availability.map((store, index) => (
                  <div key={`card-${index}`} className={`rounded-lg shadow-lg bg-white flex flex-col w-60 sm:w-96 flex-shrink-0 ${!promoCard && index === 0 ? 'ml-4 sm:ml-6 lg:ml-8' : ''} mr-4 sm:mr-6 lg:mr-8 py-2 px-3 sm:p-4`}>
                    <div className="sm:flex">
                      <div className="inline-flex rounded-lg overflow-hidden border-4 border-white bg-white h-12 w-full sm:h-24 sm:w-24 shadow-md sm:shadow-lg p-2">
                        <img className="object-contain w-full h-full" src={getLogo(store)} alt="" />
                      </div>
                      <div className="sm:ml-4 mt-1 flex-1 truncate flex flex-col justify-center items-start">
                        <div className="flex items-center truncate w-full">
                          <h3 className="font-bold text-lg text-gray-900 truncate mb-1 sm:mb-2">{store.name}</h3>
                        </div>
                        <span className={`inline-flex items-center mb-1 sm:mb-2 px-2 py-1 rounded-md text-sm font-medium ${store.appointmentsAvailable ? 'bg-green-100 text-green-800' : store.inStock ? 'bg-red-100 text-red-800' : 'bg-gray-200 text-gray-600'}`}>
                          {store.appointmentsAvailable == true ? 'Appointments' : store.inStock ? 'No Appointments' : 'Unavailable'}
                        </span>
                        {store.address && (
                          <p className="truncate text-sm sm:text-md w-full">
                            {store.address.street}
                            <br></br>
                            {store.address.city}, {store.address.state} {store.address.zip}
                          </p>
                        )}
                        {store.distance && (
                          <p className="mt-1 truncate text-sm font-semibold sm:text-md w-full">
                            {store.distance} miles
                          </p>
                        )}
                      </div>
                    </div>
                    <button onClick={e => storeClicked(e, store, index)} type="button" className="flex-shrink-0 mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      View Details
                    </button>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {selectedStore && (
        <div className="fixed top-0 right-0 z-10 w-full sm:max-w-lg lg:max-w-xl h-full flex flex-col bg-white shadow-xl overflow-hidden">
          <div className="px-4 py-4 sm:px-6 sm:py-6 border-b border-gray-200">
            <div className="flex items-start justify-between">
              <h2 id="slide-over-heading" className="text-lg font-medium text-gray-900">
                Location Detail
              </h2>
              <div className="ml-3 h-7 flex items-center">
                <button onClick={e => setSelectedStore(null)} className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500">
                  <span className="sr-only">Close panel</span>
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="divide-y divide-gray-200 overflow-y-scroll">
            <div className="pb-6">
              {/* <div className="bg-indigo-700 h-24 sm:h-20 lg:h-28"></div> */}
              <div className="flow-root px-4 sm:flex sm:items-start sm:px-6">
                <div className="-m-1 flex flex-shrink-0">
                  <div className="inline-flex rounded-lg overflow-hidden border-4 border-white bg-white h-24 w-full sm:h-24 sm:w-24 lg:w-36 lg:h-36 shadow-lg p-4">
                    <img className="object-contain w-full h-full" src={getLogo(selectedStore)} alt="" />
                  </div>
                </div>
                <div className="mt-6 sm:ml-6 sm:flex-1 truncate">
                  <div>
                    <div className="flex items-center truncate">
                      <h3 className="font-bold text-lg text-gray-900 sm:text-xl truncate">{selectedStore.name}</h3>
                    </div>
                    <p className="text-md text-gray-500">{selectedStore.brand ? selectedStore.brand.toUpperCase() : ''}</p>
                    <span className={`inline-flex items-center mt-1 sm:mt-2 px-2 py-1 rounded-md text-sm font-medium ${selectedStore.appointmentsAvailable ? 'bg-green-100 text-green-800' : selectedStore.inStock ? 'bg-red-100 text-red-800' : 'bg-gray-200 text-gray-600'}`}>
                      {selectedStore.appointmentsAvailable == true ? 'Appointments' : selectedStore.inStock ? 'No Appointments' : 'Unavailable'}
                    </span>
                  </div>
                  <div className="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                    {selectedStore.url && (
                      <button onClick={e => goToStoreSite(selectedStore.url)} type="button" className="flex-shrink-0 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:flex-1">
                        Visit Site
                      </button>
                    )}
                    {selectedStore.phone && (
                      <button onClick={e => callStore(selectedStore.phone)} type="button" className="flex-1 w-full inline-flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        Call
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 py-4 sm:px-0">
              <dl className="divide-y divide-gray-200">
                <div className="sm:flex sm:px-6 pb-4">
                  <dt className="text-sm font-medium text-gray-500 w-32 sm:flex-shrink-0">
                    Address
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2 text-right flex-grow">
                    <p>
                      {selectedStore.address.street}
                      <br></br>
                      {selectedStore.address.city}, {selectedStore.address.state} {selectedStore.address.zip}
                    </p>
                  </dd>
                </div>
                <div className="sm:flex sm:px-6 py-4">
                  <dt className="text-sm font-medium text-gray-500 w-32 sm:flex-shrink-0">
                    Distance
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2 text-right flex-grow">
                    <p>
                      {selectedStore.distance} miles
                    </p>
                  </dd>
                </div>
                {selectedStore.inventory && selectedStore.inventory.length > 0 ? selectedStore.inventory.map(i =>
                  <div key={`inv-${selectedStore.id}-${i.guid}`} className="sm:flex sm:px-6 py-4">
                    <dt className="text-sm font-medium text-gray-500 w-32 sm:flex-shrink-0">
                      {i.name || parseInventoryName(i.key || '')}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2 text-right flex-grow">
                      <span className={`inline-flex items-center px-2 py-1 rounded-md text-sm font-medium ${i.in_stock === 'TRUE' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                        {i.in_stock === 'TRUE' ? 'In Stock' : 'Out of Stock'}
                      </span>
                    </dd>
                  </div>
                ) : (
                  <div className="sm:flex sm:px-6 py-4">
                    <dt className="text-sm font-medium text-gray-500 w-32 sm:flex-shrink-0">
                      COVID-19 Vaccine
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2 text-right flex-grow">
                      <span className={`inline-flex items-center px-2 py-1 rounded-md text-sm font-medium ${selectedStore.appointmentsAvailable ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                        {selectedStore.appointmentsAvailable == true ? 'In Stock' : 'Out of Stock'}
                      </span>
                    </dd>
                  </div>
                )}
                {selectedStore.signupUrl && (
                  <div className="sm:flex sm:px-6 py-4">
                    <dt className="text-sm font-medium text-gray-500 w-32 sm:flex-shrink-0">
                      Vaccine Signup
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2 text-right flex-grow">
                      <button onClick={e => goToStoreSite(selectedStore.signupUrl)} type="button" className="flex-shrink-0 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:flex-1">
                        Sign Up
                      </button>
                    </dd>
                  </div>
                )}
                <div className="sm:flex sm:px-6 py-4">
                  <dt className="text-sm font-medium text-gray-500 w-32 sm:flex-shrink-0">
                    Time Slots
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 ml-6 flex-grow sm:mt-0">
                    <dl>
                      {(selectedStore.vaccineAvailability && selectedStore.vaccineAvailability.data && selectedStore.vaccineAvailability.data.slotDays && selectedStore.vaccineAvailability.data.slotDays.length > 0) ? selectedStore.vaccineAvailability.data.slotDays.map((day, index) => (
                        <div key={`day-${index}`} className={`sm:flex ${index === 0 ? 'pb-4' : 'py-4'}`}>
                          <dd className={`${index === 0 ? 'sm:mt-0' : ''} mt-1 text-sm text-gray-900 ml-4 flex-shrink-0 flex-grow text-right`}>
                            <p className={`${index === 0 ? 'mt-1' : 'mt-3'} sm:mt-0 text-lg font-bold text-gray-600 mb-3`}>
                              {moment(day.slotDate, 'YYYY-MM-DD').format('ddd, MMM Do, YYYY')}
                            </p>
                            {day.slots === 'Please check online' ? (
                              <p>Please check online</p>
                            ) : (day.slots && day.slots.length > 0) ? day.slots.map((slot, index) => (
                              <div key={`slot-${index}`} className="bg-white rounded-md border-gray-200 border-2 p-2 mb-2 px-4">
                                {slot.type && (
                                  <span className={`inline-flex items-center px-2 py-1 rounded-md text-sm font-medium bg-indigo-100 text-indigo-600 mb-1`}>
                                    {slotType(slot.type)}
                                  </span>
                                )}
                                {slot.startTime && slot.endTime && (
                                  <p className="text-lg font-semibold mb-1">
                                    {slot.startTime && slot.startTime.toString() && (slot.startTime.toString().includes('am') || slot.startTime.toString().includes('pm')) ? slot.startTime : moment(slot.startTime).format('h:mm a')} - {slot.endTime && slot.endTime.toString() && (slot.endTime.toString().includes('am') || slot.endTime.toString().includes('pm')) ? slot.endTime : moment(slot.endTime).format('h:mm a')}
                                  </p>
                                )}
                                <p>
                                  {moment(day.slotDate, 'YYYY-MM-DD').format('ddd, MMM Do, YYYY')}
                                </p>
                              </div>
                            )) : (
                              <p>No time slots available</p>
                            )}
                          </dd>
                        </div>
                      )) : (
                        <p>{selectedStore.appointmentsAvailable == true ? 'Please check this store\'s website for availability' : 'No time slots available'}</p>
                      )}
                    </dl>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      )}

      {showNotificationsModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen p-4 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
        
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-600">
                  <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                    Real-Time Alerts
                  </h3>
                  <div className="mt-2">
                    <p className="text-md text-gray-600">
                      Enter your info to receive real-time text message and email alerts for your current search.
                    </p>
                    <div className="text-left mt-4">
                      <label htmlFor="phone" className="block text-md leading-5 font-medium text-gray-700 mb-2">
                        Phone
                        <span onClick={showTextInfoModal} className={`cursor-pointer ml-2 inline-flex items-center px-2 py-1 rounded-md text-sm font-medium bg-indigo-100 text-indigo-600`}>
                          <i className="fa fa-lock mr-1"></i>More info
                        </span>
                      </label>
                      <InputMask
                        id="phone"
                        name="phone"
                        autoComplete="phone"
                        className="mt-1 form-input flex-1 block w-full rounded-none rounded-l-md transition duration-150 ease-in-out text-lg font-normal sm:leading-5 p-2 rounded-md shadow-sm"
                        placeholder="ex. 555-123-4567"
                        type="tel"
                        mask="999-999-9999"
                        maskChar={null}
                        value={notificationsInfo.phone}
                        onChange={e =>
                          notificationsInfoUpdating(e, 'phone')
                        }
                      />
                      {phoneError && (
                        <p className="mt-2 text-md text-red-600 font-medium" id="phone-error">{phoneError}</p>
                      )}
                    </div>
                    <div className="text-left mt-4">
                      <label htmlFor="email" className="block text-md leading-5 font-medium text-gray-700 mb-2">
                        Email
                        <span className={`ml-2 inline-flex items-center px-2 py-1 rounded-md text-sm font-medium bg-green-100 text-green-600`}>
                          <i className="fa fa-lock-open mr-1"></i>Free
                        </span>
                      </label>
                      <input
                        id="email"
                        name="email"
                        autoComplete="email"
                        className="mt-1 form-input flex-1 block w-full rounded-none rounded-l-md transition duration-150 ease-in-out text-lg font-normal sm:leading-5 p-2 rounded-md shadow-sm"
                        placeholder="ex. name@test.com"
                        type="email"
                        value={notificationsInfo.email}
                        onChange={e =>
                          notificationsInfoUpdating(e, 'email')
                        }
                      />
                      {emailError && (
                        <p className="mt-2 text-md text-red-600 font-medium" id="email-error">{emailError}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button onClick={e => subscribeForNotifications()} type="button" className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm ${!isEnabled() ? 'bg-gray-200 text-gray-600 hover:bg-gray-300' : 'bg-indigo-600 text-white hover:bg-indigo-700'}`}>
                  Sign Up
                </button>
                <button onClick={e => setShowNotificationsModal(false)} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MapSection;
